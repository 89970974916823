import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Container,
  Center,
  Flex,
  Image,
  Heading,
  ChakraProvider,
  chakra,
  Link,
  VStack,
  Button,
  HStack,
  Icon,
} from '@chakra-ui/react';
import ReactAudioPlayer from 'react-audio-player';
import audioSong from '../../audio/test.mp3';
import SubHeader from '../SubHeader';
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaSpotify,
  FaPlayCircle,
  FaRegPlayCircle,
} from 'react-icons/fa';

//audio player testing
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

//audio imports

import terrestrialObsolescence from '../../audio/goreInSpace/01TerrestrialObsolescence.wav';
import anEkpyroticNecrotomy from '../../audio/goreInSpace/02AnEkpyroticNecrotomy.wav';
import paradoxicalCosmologist from '../../audio/goreInSpace/03ParadoxicalCosmologist.wav';
import reducedToPrimordialOoze from '../../audio/goreInSpace/04ReducedToPrimordialOoze.wav';
import intergalacticSanitarium from '../../audio/goreInSpace/05IntergalacticSanitarium.wav';
import purulentWormhole from '../../audio/goreInSpace/06PurulentWormhole.wav';

const GoreInSpaceFull = () => {
  //album data
  const songs = [
    {
      title: '1. Terrestrial Obsolescence',
      audioFile: terrestrialObsolescence,
    },
    {
      title: '2. An Ekpyrotic Necrotomy',
      audioFile: anEkpyroticNecrotomy,
    },
    {
      title: '3. Paradoxical Cosmologist',
      audioFile: paradoxicalCosmologist,
    },
    {
      title: '4. Reduced To Primordial Ooze',
      audioFile: reducedToPrimordialOoze,
    },
    {
      title: '5. Intergalactic Sanitarium',
      audioFile: intergalacticSanitarium,
    },
    {
      title: '6. Purulent Wormhole',
      audioFile: purulentWormhole,
    },
  ];

  //state variables
  const [currentlyPlayingTitle, setCurrentlyPlayingTitle] = useState(
    '1. Terrestrial Obsolescence'
  );
  const [srcAudio, setSrcAudio] = useState();

  //audio track click handler
  const audioClick = title => {
    setSrcAudio();
    setCurrentlyPlayingTitle(title);
  };

  //useEffect for src audio
  useEffect(() => {
    songs.map(song => {
      if (song.title === currentlyPlayingTitle) {
        setSrcAudio(song.audioFile);
      }
    });
  }, [currentlyPlayingTitle]);

  return (
    <>
      <SubHeader />
      <Container
        mb={{ base: 10, md: 0, lg: 36 }}
        mt={{ base: 0, md: 0, lg: 0 }}
        maxW={'6xl'}
      >
        <Box>
          <Center>
            <Flex
              direction={{ base: 'column', md: '', lg: 'row' }}
              mt={{ base: 8, md: 0, lg: 20 }}
            >
              <Flex
                direction={'column'}
                // mt={4}
              >
                <Box
                  display={{ base: 'block', md: '', lg: 'none' }}
                  mb={10}
                  mt={10}
                >
                  <Image
                    // maxW={'2xl'}
                    // maxW={{ base: 'sm', md: 'xl', lg: '2xl' }}
                    // px={{ base: 4, md: 3, lg: 5 }}
                    w="full"
                    src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/maxresdefault_uN0WYYG-UX.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1678157710921"
                  />
                </Box>
                <Heading color="gray.300" mb={6}>
                  Colostomy Spacebag - Gore In Space (2019)
                </Heading>
                <Box mb={0}>
                  <Box mb={0} p={4}>
                    <Heading
                      color="white"
                      pb={4}
                      fontSize="3xl"
                      // color="gray.300"
                    >
                      {currentlyPlayingTitle}
                    </Heading>
                    <ReactAudioPlayer src={srcAudio} controls autoPlay />
                  </Box>

                  {songs.map(song => (
                    <div>
                      <HStack m={2}>
                        <Icon
                          h="25px"
                          w="25px"
                          color="gray.300"
                          _hover={{
                            transform: 'scale(1.2)',
                            color: 'green.300',
                          }}
                          as={FaPlayCircle}
                          onClick={() => audioClick(song.title)}
                        />

                        <Text
                          mb={3}
                          mt={3}
                          color="gray.300"
                          fontSize={{ base: 'md', md: '', lg: 'lg' }}
                        >
                          {song.title}
                        </Text>
                      </HStack>
                    </div>
                  ))}
                </Box>
              </Flex>
              <Flex direction={'column'}>
                <Box
                  display={{ base: 'none', md: '', lg: 'block' }}
                  maxW="600px"
                >
                  <Image src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/maxresdefault_uN0WYYG-UX.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1678157710921" />
                </Box>
                <Box color="gray.400" mt={4} fontStyle={'italic'}>
                  <chakra.h2 fontSize={'lg'} fontWeight="900" mb={4}>
                    Colostomy Spacebag - Gore In Space (2019)
                  </chakra.h2>

                  <Box mb={4}>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Track Listing :
                    </chakra.h2>
                    <Text>1. Terrestrial Obsolescence</Text>
                    <Text>2. An Ekpyrotic Necrotomy</Text>
                    <Text>3. Paradoxical Cosmologist</Text>
                    <Text>4. Reduced To Primordial Ooze</Text>
                    <Text>5. Intergalactic Sanitarium</Text>
                    <Text>6. Purulent Wormhole</Text>
                  </Box>

                  <Box>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Credits :
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'}>
                      Colostomy Spacebag on this recording is
                    </chakra.h2>
                    <Text>Tom : Drums</Text>
                    <Text>Steve : Guitars, Bass, Vocals</Text>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Drums recorded by Hambone in Dunsmuir, California in 2018.
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Guitar, Bass and Vocals recorded by Will Killingsworth in
                      Massachusetts in 2019.
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Mixed and mastered by Will Killingsworth (Dead Air
                      Studios) 2019.
                    </chakra.h2>

                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Artwork by Gruesome Graphx. Layout by Hambone.
                    </chakra.h2>
                  </Box>

                  <VStack
                    align={'left'}
                    w="150px"
                    mt={7}
                    spacing={4}
                    // mx={{ base: '30%', md: '', lg: 'none' }}
                  >
                    <Link
                      href="https://drive.google.com/drive/folders/1FZZ1w3Sec659i1FyzANZPkbPZNcvCgup?usp=sharing"
                      target="_blank"
                    >
                      <Button
                        color="white"
                        bg="blue.500"
                        _hover={{ bg: 'blue.400', transform: 'scale(1.05)' }}
                      >
                        Download
                      </Button>
                    </Link>

                    {/* <Button
                      color="white"
                      bg="green.500"
                      _hover={{ bg: 'green.400', transform: 'scale(1.05)' }}
                    >
                      Donate
                    </Button> */}
                    {/* <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Vinyl
                    </Button>
                    <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Cassette
                    </Button> */}

                    <HStack spacing={4}>
                      <Link
                        href="https://www.youtube.com/watch?v=vR5UThMOjuo&ab_channel=GoreGrinder"
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaYoutube
                          color="red"
                          size={35}
                          _hover={{ transform: 'scale(2)' }}
                        />
                      </Link>
                      {/* <Link
                        href=""
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaSpotify color="lime" size={30} />
                      </Link> */}
                    </HStack>
                  </VStack>
                </Box>
              </Flex>
            </Flex>
          </Center>
        </Box>
      </Container>
    </>
  );
};

export default GoreInSpaceFull;

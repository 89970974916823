import React from 'react';

import {
  Flex,
  Box,
  Image,
  Link,
  chakra,
  useColorModeValue,
  IconButton,
  Stack,
  Heading,
  Button,
  VStack,
  HStack,
} from '@chakra-ui/react';

const Landing = ({ albumScrollHandle, merchScrollHandle }) => {
  const bg = useColorModeValue('white', 'gray.800');
  return (
    <>
      <Box
        w="full"
        h="container.md"
        // backgroundImage="url(https://ik.imagekit.io/v66nb6oaq/Haggus/hagguslive_TfglB3ND-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664838400678)"
        bgPos="center"
        bgSize="cover"
        // mb={36}
        mb={{ base: 0, md: 10, lg: 36 }}
      >
        <Flex
          align="center"
          // pos="relative"
          justify="center"
          boxSize="full"
          // mt={{ base: 16, md: 24, lg: 36 }}
          bg="blackAlpha.700"
        >
          <Stack textAlign="center" alignItems="center" spacing={6}>
            {/* <Heading
              fontSize={['2xl', , '3xl']}
              fontWeight="semibold"
              color="white"
              textTransform="uppercase"
            >
              Build Your new{' '}
              <chakra.span color="blue.400" textDecor="underline">
                Saas
              </chakra.span>
            </Heading> */}
            <Image
              // maxW={'2xl'}
              maxW={{ base: 'md', md: 'xl', lg: '4xl' }}
              px={{ base: 4, md: 3, lg: 5 }}
              src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/mincecore_ZHpwLZ77WY.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677383758870"
            />
            <HStack spacing={4}>
              <Button
                color="white"
                bg={'red.500'}
                textTransform="uppercase"
                w="fit-content"
                _hover={{ color: 'red.500', bg: 'gray.100' }}
                onClick={() => albumScrollHandle()}
              >
                LISTEN NOW
              </Button>
              {/* <Button
                color="white"
                bg={'gray.800'}
                textTransform="uppercase"
                w="fit-content"
                _hover={{ color: 'black', bg: 'white' }}
                onClick={() => merchScrollHandle()}
              >
                SHOP NOW
              </Button> */}
            </HStack>
          </Stack>
        </Flex>
      </Box>
    </>
  );
};

export default Landing;

//daily push again and again and again and again and again and again

import {
  SimpleGrid,
  Box,
  Container,
  Stack,
  Heading,
  Text,
  Button,
  Link,
  Center,
  Image,
  VStack,
  ChakraProvider,
  chakra,
} from '@chakra-ui/react';
import React from 'react';
import CapitalismCard from './merchpreviewcards/CapitalismCard';
import HorrendousCard from './merchpreviewcards/HorrendousCard';
import PigHoodieCard from './merchpreviewcards/PigHoodieCard';

const MerchMap = ({ merchScrollRef }) => {
  return (
    <>
      <Container maxW={'6xl'} mb={10} mt={20}>
        <Center>
          <VStack>
            <Heading
              fontStyle={'italic'}
              fontSize="5xl"
              color="white"
              mb={4}
              ref={merchScrollRef}
            >
              ABOUT
            </Heading>
            <Image
              mt={{ base: 4, md: 3, lg: 5 }}
              maxW={{ base: 'sm', md: 'xl', lg: '2xl' }}
              px={{ base: 4, md: 3, lg: 5 }}
              src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/tom3_z5LsnnxA4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677384881463"
            />

            <Text color="white" align={'center'} pt={10} px={10}>
              <chakra.span fontSize={18}>
                - WELCOME TO MINCECORE.COM -
              </chakra.span>
              <br />
              <br /> The official discography page of Hambone aka Tom Haggus.
              <br />
              <br />
              This website serves to document my lifelong dedication to extreme
              underground music.
              <br />
              <br />
              Fuck copyright, fuck the mainstream and fuck big corporations
              trying to capitalize off the underground.
              <br />
              <br />
              <Center>
                <Image
                  my={6}
                  maxW={'200px'}
                  src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/artworks-000150218475-7t4uhe-t500x500_7iii42aFRn.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1677741485692"
                />
              </Center>
              If you'd like to get in touch for any reason feel free to write me
              here :
              <br />
              <chakra.span fontSize={18}>Mincecore.com@gmail.com</chakra.span>
              <br />
              <br />
              If you'd like to donate and help support this page :
              <br />
              <chakra.span fontSize={18}>
                PayPal: fylrecords@yahoo.com | Venmo: @mincecore
              </chakra.span>
              <br />
            </Text>
            {/* <Image
              mt={{ base: 4, md: 3, lg: 5 }}
              maxW={{ base: 'sm', md: 'xl', lg: '2xl' }}
              px={{ base: 4, md: 3, lg: 5 }}
              src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/tom3_z5LsnnxA4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677384881463"
            /> */}
          </VStack>
        </Center>
        {/* <SimpleGrid columns={[1, null, 3]} spacing={20}>
          <Link href="/straight-from-the-slaughterhaus">
            <PigHoodieCard />
            <Button colorScheme="green">View In Shop</Button>
          </Link>
          <Box>
            <PigHoodieCard />
          </Box>
          <Box>
            <HorrendousCard />
          </Box>
          <Box>
            <CapitalismCard />
          </Box>
        </SimpleGrid> */}
      </Container>
    </>
  );
};

export default MerchMap;

import React, { useState, useRef } from 'react';
import AlbumMap from './AlbumMap';
import MerchMap from './MerchMap';
import Landing from './Landing';
import { Box } from '@chakra-ui/react';
import TunkioSplit from './TunkioSplit';
import Header from './Header';

const Home = ({
  albumScrollHandle,
  albumScrollRef,
  merchScrollHandle,
  merchScrollRef,
}) => {
  return (
    <>
      <Header
        albumScrollHandle={albumScrollHandle}
        merchScrollHandle={merchScrollHandle}
      />

      <Landing
        albumScrollHandle={albumScrollHandle}
        merchScrollHandle={merchScrollHandle}
      />

      <Box bg="black" p={2} mt={10} mb={10}>
        <AlbumMap albumScrollRef={albumScrollRef} />
      </Box>
      <Box bg="black" p={2} mt={20} mb={10}>
        <MerchMap merchScrollRef={merchScrollRef} />
      </Box>
    </>
  );
};

export default Home;

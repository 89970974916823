import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Container,
  Center,
  Flex,
  Image,
  Heading,
  ChakraProvider,
  chakra,
  Button,
  VStack,
  HStack,
  Link,
  Icon,
} from '@chakra-ui/react';
import ReactAudioPlayer from 'react-audio-player';
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaSpotify,
  FaPlayCircle,
} from 'react-icons/fa';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import SubHeader from '../SubHeader';

import { Helmet } from 'react-helmet';

//audio imports
import psychologicalNecrosis from '../../audio/slaughterhaus/01PsychologicalNecrosis.mp3';
import septicBirthDefects from '../../audio/slaughterhaus/02SepticBirthDefects.mp3';
import extremelyDeformed from '../../audio/slaughterhaus/03ExtremelyDeformed.mp3';
import plasticMincer from '../../audio/slaughterhaus/04PlasticMincer.mp3';
import humanityAtWhatCost from '../../audio/slaughterhaus/05HumanityAtWhatCost.mp3';
import uneducatedPolitician from '../../audio/slaughterhaus/06UneducatedPolitician.mp3';
import terminallyInfernal from '../../audio/slaughterhaus/07TerminallyInfernal.mp3';
import socialErosion from '../../audio/slaughterhaus/08SocialErosion.mp3';
import toWhatFuckingEnd from '../../audio/slaughterhaus/09ToWhatFuckingEnd.mp3';
import apologyForPraexology from '../../audio/slaughterhaus/10ApologyForPraexology.mp3';
import disgustAndAbuse from '../../audio/slaughterhaus/11DisgustAndAbuse.mp3';
import anEpitaphForMankind from '../../audio/slaughterhaus/12AnEpitaphForMankind.mp3';

const SlaughterhausFull = () => {
  //album data
  const songs = [
    {
      title: '1. Psychological Necrosis',
      audioFile: psychologicalNecrosis,
    },
    {
      title: '2. Septic Birth Defects',
      audioFile: septicBirthDefects,
    },
    {
      title: '3. Extremely Deformed',
      audioFile: extremelyDeformed,
    },
    {
      title: '4. Plastic Mincer',
      audioFile: plasticMincer,
    },
    {
      title: '5. Humanity At What Cost?',
      audioFile: humanityAtWhatCost,
    },
    {
      title: '6. Uneducated Politician',
      audioFile: uneducatedPolitician,
    },
    {
      title: '7. Terminally Infernal',
      audioFile: terminallyInfernal,
    },
    {
      title: '8. Social Erosion',
      audioFile: socialErosion,
    },
    {
      title: '9. To What Fucking End?',
      audioFile: toWhatFuckingEnd,
    },
    {
      title: '10. Apology For Praxeology',
      audioFile: apologyForPraexology,
    },
    {
      title: '11. Disgust And Abuse',
      audioFile: disgustAndAbuse,
    },
    {
      title: '12. An Epitaph For Mankind',
      audioFile: anEpitaphForMankind,
    },
  ];

  //state variables
  const [currentlyPlayingTitle, setCurrentlyPlayingTitle] = useState(
    '1. Psychological Necrosis'
  );
  const [srcAudio, setSrcAudio] = useState(psychologicalNecrosis);

  //audio track click handler
  const audioClick = title => {
    setSrcAudio();
    setCurrentlyPlayingTitle(title);
  };

  //useEffect for src audio
  useEffect(() => {
    songs.map(song => {
      if (song.title === currentlyPlayingTitle) {
        setSrcAudio(song.audioFile);
      }
    });
  }, [currentlyPlayingTitle]);

  return (
    <>
      <div>
        <Helmet>
          <title>Haggus | Slaughterhaus</title>
        </Helmet>
      </div>
      <SubHeader />

      <Container
        mb={{ base: 10, md: 0, lg: 36 }}
        mt={{ base: 0, md: 0, lg: 0 }}
        maxW={'6xl'}
      >
        <Box>
          <Center>
            <Flex
              direction={{ base: 'column', md: '', lg: 'row' }}
              mt={{ base: 8, md: 0, lg: 20 }}
            >
              <Flex
                direction={'column'}
                // mt={4}
              >
                <Box
                  display={{ base: 'block', md: '', lg: 'none' }}
                  mb={10}
                  mt={10}
                >
                  <Image
                    // maxW={'2xl'}
                    // maxW={{ base: 'sm', md: 'xl', lg: '2xl' }}
                    // px={{ base: 4, md: 3, lg: 5 }}
                    src="https://ik.imagekit.io/v66nb6oaq/Haggus/slaughterhaus_u1bKIw3As.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1667938481786"
                  />
                </Box>
                <Heading color="gray.300" mb={4}>
                  Haggus - Straight From The Slaughterhaus (2020)
                </Heading>
                <Box mb={0}>
                  <Box mb={0} p={4}>
                    <Heading
                      color="white"
                      pb={4}
                      fontSize="3xl"
                      color="gray.300"
                    >
                      {currentlyPlayingTitle}
                    </Heading>
                    <ReactAudioPlayer src={srcAudio} controls autoPlay />
                  </Box>

                  {songs.map(song => (
                    <div>
                      <HStack m={2}>
                        <Icon
                          h="25px"
                          w="25px"
                          color="gray.300"
                          _hover={{
                            transform: 'scale(1.2)',
                            color: 'green.300',
                          }}
                          as={FaPlayCircle}
                          onClick={() => audioClick(song.title)}
                        />

                        <Text
                          mb={3}
                          mt={3}
                          color="gray.300"
                          fontSize={{ base: 'md', md: '', lg: 'lg' }}
                        >
                          {song.title}
                        </Text>
                      </HStack>
                    </div>
                  ))}
                </Box>
              </Flex>
              <Flex direction={'column'}>
                <Box display={{ base: 'none', md: '', lg: 'block' }}>
                  <Image src="https://ik.imagekit.io/v66nb6oaq/Haggus/slaughterhaus_u1bKIw3As.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1667938481786" />
                </Box>
                <Box color="gray.400" mt={4} fontStyle={'italic'}>
                  <chakra.h2 fontSize={'lg'} fontWeight="900" mb={4}>
                    Haggus - Straight From The Slaughterhaus (2020)
                  </chakra.h2>

                  <Box mb={4}>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Track Listing :
                    </chakra.h2>
                    <Text>1. Psychological Necrosis</Text>
                    <Text>2. Septic Birth Defects</Text>
                    <Text>3. Extremely Deformed</Text>
                    <Text>4. Plastic Mincer</Text>
                    <Text>5. Humanity At What Cost?</Text>
                    <Text>6. Uneducated Politician</Text>
                    <Text>7. Terminally Infernal</Text>
                    <Text>8. Social Erosion </Text>
                    <Text>9. To What Fucking End?</Text>
                    <Text>10. Apology For Praxaeology</Text>
                    <Text>11. Disgust And Abuse</Text>
                    <Text>12. An Epitaph For Mankind</Text>
                  </Box>

                  <Box>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Credits :
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'}>
                      Haggus on this recording is
                    </chakra.h2>
                    <Text>Hambone : Guitar, Bass, Vocals</Text>
                    <Text>Lil Giz : Drums, Vocals</Text>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Recorded and mixed by Hambone in Oakland, California.
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'}>
                      Mastered by Brad Smith in Baltimore, Maryland.
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Artwork by Morbid Mark.
                    </chakra.h2>
                  </Box>

                  <VStack
                    align={'left'}
                    w="150px"
                    mt={7}
                    spacing={4}
                    // mx={{ base: '30%', md: '', lg: 'none' }}
                  >
                    <Button
                      color="white"
                      bg="blue.500"
                      _hover={{ bg: 'blue.400', transform: 'scale(1.05)' }}
                    >
                      Download
                    </Button>
                    <Button
                      color="white"
                      bg="green.500"
                      _hover={{ bg: 'green.400', transform: 'scale(1.05)' }}
                    >
                      Donate
                    </Button>
                    <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Vinyl
                    </Button>
                    <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Cassette
                    </Button>

                    <HStack spacing={4}>
                      <Link
                        href="https://www.youtube.com/watch?v=34AVebZPCwI&t=1554s"
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaYoutube
                          color="red"
                          size={35}
                          _hover={{ transform: 'scale(2)' }}
                        />
                      </Link>
                      <Link
                        href=""
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaSpotify color="lime" size={30} />
                      </Link>
                    </HStack>
                  </VStack>
                </Box>
              </Flex>
            </Flex>
          </Center>
        </Box>
      </Container>
    </>
  );
};

export default SlaughterhausFull;

//OLD PRE-RESPONSIVE DESIGN, SAVE UNTIL RESPONSIVENESS FINALIZED
{
  /* <Box py={2} mb={2} color="white">
                <Heading>
                  Haggus - Straight From The Slaughterhaus (2020)
                </Heading>
                <Box color="gray.400" mt={4} fontStyle={'italic'}>
                  <chakra.h2 fontSize={'lg'}>
                    Haggus on this recording is
                  </chakra.h2>
                  <Text>Hambone : Guitar, Bass, Vocals</Text>
                  <Text>Lil Giz : Drums, Vocals</Text>
                  <chakra.h2 fontSize={'lg'} mt={2}>
                    Recorded, mixed and mastered by Hambone in Oakland,
                    California.
                  </chakra.h2>
                  <chakra.h2 fontSize={'lg'}>Artwork by Morbid Mark.</chakra.h2>
                </Box>
              </Box>

              <AudioPlayer
                autoPlay
                src={audioSong}
                onPlay={e => console.log('onPlay')}
                layout="horizontal-reverse"
              /> */
}

//testtingdsaf

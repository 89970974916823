import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Container,
  Center,
  Flex,
  Image,
  Heading,
  ChakraProvider,
  chakra,
  Link,
  VStack,
  Button,
  HStack,
  Icon,
} from '@chakra-ui/react';
import ReactAudioPlayer from 'react-audio-player';
import audioSong from '../../audio/test.mp3';
import SubHeader from '../SubHeader';
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaSpotify,
  FaPlayCircle,
  FaRegPlayCircle,
} from 'react-icons/fa';

//audio player testing
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

//audio imports
import riddledWithBullets from '../../audio/biologicalExtinction/01RiddledWithBullets.wav';
import anotherBodyInTheTrunk from '../../audio/biologicalExtinction/02AnotherBodyInTheTrunk.wav';
import funeraryDisgust from '../../audio/biologicalExtinction/03FuneraryDisgust.wav';
import glockTherapy from '../../audio/biologicalExtinction/04GlockTherapy.wav';
import pastThePointOfPulverized from '../../audio/biologicalExtinction/05PastThePointOfPulverized.wav';
import theActOfKilling from '../../audio/biologicalExtinction/06TheActOfKilling.wav';
import tapestryOfFlesh from '../../audio/biologicalExtinction/07TapestryOfFlesh.wav';
import reducedToChunksOfCartilage from '../../audio/biologicalExtinction/08ReducedToChunksOfCartilage.wav';
import snitchInADitch from '../../audio/biologicalExtinction/09SnitchInADitch.wav';
import oneWayTicketToTheMorgue from '../../audio/biologicalExtinction/10OneWayTicketToTheMorgue.wav';

const BiologicalExtinctionFull = () => {
  //album data
  const songs = [
    {
      title: '1. Riddled With Bullets',
      audioFile: riddledWithBullets,
    },
    {
      title: '2. Another Body In The Trunk',
      audioFile: anotherBodyInTheTrunk,
    },
    {
      title: '3. Funerary Disgust',
      audioFile: funeraryDisgust,
    },
    {
      title: '4. Glock Therapy',
      audioFile: glockTherapy,
    },
    {
      title: '5. Past The Point Of Pulverized',
      audioFile: pastThePointOfPulverized,
    },
    {
      title: '6. The Act Of Killing',
      audioFile: theActOfKilling,
    },
    {
      title: '7. Tapestry Of Flesh',
      audioFile: tapestryOfFlesh,
    },
    {
      title: '8. Reduced To Chunks Of Cartilage',
      audioFile: reducedToChunksOfCartilage,
    },
    {
      title: '9. Snitch In A Ditch',
      audioFile: snitchInADitch,
    },
    {
      title: '10. One Way Ticket To The Morgue',
      audioFile: oneWayTicketToTheMorgue,
    },
  ];

  //state variables
  const [currentlyPlayingTitle, setCurrentlyPlayingTitle] = useState(
    '1. Riddled With Bullets'
  );
  const [srcAudio, setSrcAudio] = useState(riddledWithBullets);

  //audio track click handler
  const audioClick = title => {
    setSrcAudio();
    setCurrentlyPlayingTitle(title);
  };

  //useEffect for src audio
  useEffect(() => {
    songs.map(song => {
      if (song.title === currentlyPlayingTitle) {
        setSrcAudio(song.audioFile);
      }
    });
  }, [currentlyPlayingTitle]);

  return (
    <>
      <SubHeader />
      <Container
        mb={{ base: 10, md: 0, lg: 36 }}
        mt={{ base: 0, md: 0, lg: 0 }}
        maxW={'6xl'}
      >
        <Box>
          <Center>
            <Flex
              direction={{ base: 'column', md: '', lg: 'row' }}
              mt={{ base: 8, md: 0, lg: 20 }}
            >
              <Flex
                direction={'column'}
                // mt={4}
              >
                <Box
                  display={{ base: 'block', md: '', lg: 'none' }}
                  mb={10}
                  mt={10}
                >
                  <Image
                    // maxW={'2xl'}
                    // maxW={{ base: 'sm', md: 'xl', lg: '2xl' }}
                    // px={{ base: 4, md: 3, lg: 5 }}
                    src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/BiologicalExtinction_s9BQ4QNKFZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677524141262"
                  />
                </Box>
                <Heading color="gray.300" mb={6}>
                  Goredozer - Biological Extinction (2019)
                </Heading>
                <Box mb={0}>
                  <Box mb={0} p={4}>
                    <Heading
                      color="white"
                      pb={4}
                      fontSize="3xl"
                      // color="gray.300"
                    >
                      {currentlyPlayingTitle}
                    </Heading>
                    <ReactAudioPlayer src={srcAudio} controls autoPlay />
                  </Box>

                  {songs.map(song => (
                    <div>
                      <HStack m={2}>
                        <Icon
                          h="25px"
                          w="25px"
                          color="gray.300"
                          _hover={{
                            transform: 'scale(1.2)',
                            color: 'green.300',
                          }}
                          as={FaPlayCircle}
                          onClick={() => audioClick(song.title)}
                        />

                        <Text
                          mb={3}
                          mt={3}
                          color="gray.300"
                          fontSize={{ base: 'md', md: '', lg: 'lg' }}
                        >
                          {song.title}
                        </Text>
                      </HStack>
                    </div>
                  ))}
                </Box>
              </Flex>
              <Flex direction={'column'}>
                <Box
                  display={{ base: 'none', md: '', lg: 'block' }}
                  maxW="600px"
                >
                  <Image src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/BiologicalExtinction_s9BQ4QNKFZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677524141262" />
                </Box>
                <Box color="gray.400" mt={4} fontStyle={'italic'}>
                  <chakra.h2 fontSize={'lg'} fontWeight="900" mb={4}>
                    Goredozer - Biological Extinction (2019)
                  </chakra.h2>

                  <Box mb={4}>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Track Listing :
                    </chakra.h2>
                    <Text>1. Riddled With Bullets</Text>
                    <Text>2. Another Body In The Trunk</Text>
                    <Text>3. Funerary Disgust</Text>
                    <Text>4. Glock Therapy</Text>
                    <Text>5. Past The Point Of Pulverized</Text>
                    <Text>6. The Act Of Killing</Text>
                    <Text>7. Tapestry Of Flesh</Text>
                    <Text>8. Reduced To Chunks Of Cartilage </Text>
                    <Text>9. Snitch In A Ditch</Text>
                    <Text>10. One Way Ticket To The Morgue</Text>
                  </Box>

                  <Box>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Credits :
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'}>
                      Goredozer on this recording is
                    </chakra.h2>
                    <Text>Hambone : Drums, Guitar, Bass, Vocals</Text>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Recorded, mixed and mastered by Hambone <br /> in
                      Dunsmuir, California throughout 2018-2019.
                    </chakra.h2>

                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Artwork by Morbid Mark. Layout by Hambone.
                    </chakra.h2>
                  </Box>

                  <VStack
                    align={'left'}
                    w="150px"
                    mt={7}
                    spacing={4}
                    // mx={{ base: '30%', md: '', lg: 'none' }}
                  >
                    <Link
                      href="https://drive.google.com/drive/folders/1Z8lwZDCEdVdvk-IfJgrMT4WexJKcpRcg?usp=sharing"
                      target="_blank"
                    >
                      <Button
                        color="white"
                        bg="blue.500"
                        _hover={{ bg: 'blue.400', transform: 'scale(1.05)' }}
                      >
                        Download
                      </Button>
                    </Link>

                    {/* <Button
                      color="white"
                      bg="green.500"
                      _hover={{ bg: 'green.400', transform: 'scale(1.05)' }}
                    >
                      Donate
                    </Button> */}
                    {/* <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Vinyl
                    </Button>
                    <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Cassette
                    </Button> */}

                    <HStack spacing={4}>
                      <Link
                        href="https://www.youtube.com/watch?v=PjNFJT-UC0k&t=13s"
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaYoutube
                          color="red"
                          size={35}
                          _hover={{ transform: 'scale(2)' }}
                        />
                      </Link>
                      {/* <Link
                        href=""
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaSpotify color="lime" size={30} />
                      </Link> */}
                    </HStack>
                  </VStack>
                </Box>
              </Flex>
            </Flex>
          </Center>
        </Box>
      </Container>
    </>
  );
};

export default BiologicalExtinctionFull;

//commenting for reach

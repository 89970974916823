import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Container,
  Center,
  Flex,
  Image,
  Heading,
  ChakraProvider,
  chakra,
  HStack,
  Icon,
  VStack,
  Button,
  Link,
} from '@chakra-ui/react';
import ReactAudioPlayer from 'react-audio-player';
import audioSong from '../../audio/test.mp3';
import { FaSpotify, FaPlayCircle, FaYoutube } from 'react-icons/fa';
import SubHeader from '../SubHeader';

const MoreGoreFull = () => {
  //album data
  const songs = [
    {
      title: '1. Putrid Process',
      audioFile: { audioSong },
    },
    {
      title: '2. Backyard Lobotomy',
      audioFile: { audioSong },
    },
    {
      title: '3. Mince Is Protest',
      audioFile: { audioSong },
    },
    {
      title: '4. Regrets Of A Sodomized Carnivore',
      audioFile: { audioSong },
    },
    {
      title: '5. Mincing In Your Coffin',
      audioFile: { audioSong },
    },
    {
      title: '6. Horrible Person',
      audioFile: { audioSong },
    },
    {
      title: '7. I Wanna Rot',
      audioFile: { audioSong },
    },
    {
      title: '8. Minced Into Mush',
      audioFile: { audioSong },
    },
    {
      title: '9. Canadian Hoser (Archagathus)',
      audioFile: { audioSong },
    },
    {
      title: '10. The Power Of Positive Drinking',
      audioFile: { audioSong },
    },
  ];

  //state variables
  const [currentlyPlayingTitle, setCurrentlyPlayingTitle] = useState(
    '1. Psychological Necrosis'
  );
  const [srcAudio, setSrcAudio] = useState(audioSong);

  //audio track click handler
  const audioClick = title => {
    setSrcAudio();
    setCurrentlyPlayingTitle(title);
  };

  //useEffect for src audio
  useEffect(() => {
    songs.map(song => {
      if (song.title === currentlyPlayingTitle) {
        setSrcAudio(song.audioFile);
      }
    });
  }, [currentlyPlayingTitle]);
  return (
    <>
      <SubHeader />

      <Container
        mb={{ base: 10, md: 0, lg: 36 }}
        mt={{ base: 0, md: 0, lg: 0 }}
        maxW={'6xl'}
      >
        <Box>
          <Center>
            <Flex
              direction={{ base: 'column', md: '', lg: 'row' }}
              mt={{ base: 8, md: 0, lg: 20 }}
            >
              <Flex
                direction={'column'}
                // mt={4}
              >
                <Box
                  display={{ base: 'block', md: '', lg: 'none' }}
                  mb={10}
                  mt={10}
                >
                  <Image
                    // maxW={'2xl'}
                    // maxW={{ base: 'sm', md: 'xl', lg: '2xl' }}
                    // px={{ base: 4, md: 3, lg: 5 }}
                    src="https://ik.imagekit.io/v66nb6oaq/Haggus/moregore_BOz1tdwze.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667940200317"
                  />
                </Box>

                <Box mb={0}>
                  <Box mb={0}>
                    <Heading color="gray.300" mb={4} px={4}>
                      Haggus - Gore, Gore And More Gore (2017)
                    </Heading>
                    <Box p={4}>
                      <Heading
                        color="white"
                        pb={4}
                        fontSize="3xl"
                        color="gray.300"
                      >
                        {currentlyPlayingTitle}
                      </Heading>
                      <ReactAudioPlayer src={srcAudio} controls autoPlay />
                    </Box>
                  </Box>

                  {songs.map(song => (
                    <div>
                      <HStack m={2}>
                        <Icon
                          h="25px"
                          w="25px"
                          color="gray.300"
                          _hover={{
                            transform: 'scale(1.2)',
                            color: 'green.300',
                          }}
                          as={FaPlayCircle}
                          onClick={() => audioClick(song.title)}
                        />

                        <Text
                          mb={3}
                          mt={3}
                          color="gray.300"
                          fontSize={{ base: 'md', md: '', lg: 'lg' }}
                        >
                          {song.title}
                        </Text>
                      </HStack>
                    </div>
                  ))}
                </Box>
              </Flex>
              <Flex direction={'column'}>
                <Box display={{ base: 'none', md: '', lg: 'block' }}>
                  <Image src="https://ik.imagekit.io/v66nb6oaq/Haggus/moregore_BOz1tdwze.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667940200317" />
                </Box>
                <Box color="gray.400" mt={4} fontStyle={'italic'}>
                  <chakra.h2 fontSize={'lg'} fontWeight="900" mb={4}>
                    Haggus - Gore, Gore And More Gore (2017)
                  </chakra.h2>

                  <Box mb={4}>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Track Listing :
                    </chakra.h2>
                    <Text>1. Putrid Process</Text>
                    <Text>2. Backyard Lobotomy</Text>
                    <Text>3. Mince Is Protest</Text>
                    <Text>4. Regrets Of A Sodomized Carnivore</Text>
                    <Text>5. Mincing In Your Coffin</Text>
                    <Text>6. Horrible Person</Text>
                    <Text>7. I Wanna Rot</Text>
                    <Text>8. Minced Into Mush </Text>
                    <Text>9. Canadian Hoser (Archagathus)</Text>
                    <Text>10. The Power Of Positive Drinking</Text>
                  </Box>

                  <Box>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Credits :
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'}>
                      Haggus on this recording is
                    </chakra.h2>
                    <Text>Hambone : Guitar, Vocals</Text>
                    <Text>Gorehog : Bass</Text>
                    <Text>Lil Giz : Drums</Text>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Recorded by Dillard in San Antonio, Texas in March 2017.
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'}>
                      Mixed and mastered by Vernon Friday in San Antonio, Texas.
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Artwork by Morbid Mark.
                    </chakra.h2>
                  </Box>

                  <VStack
                    align={'left'}
                    w="150px"
                    mt={7}
                    spacing={4}
                    // mx={{ base: '30%', md: '', lg: 'none' }}
                  >
                    <Button
                      color="white"
                      bg="blue.500"
                      _hover={{ bg: 'blue.400', transform: 'scale(1.05)' }}
                    >
                      Download
                    </Button>
                    <Button
                      color="white"
                      bg="green.500"
                      _hover={{ bg: 'green.400', transform: 'scale(1.05)' }}
                    >
                      Donate
                    </Button>
                    <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Vinyl
                    </Button>
                    <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Cassette
                    </Button>

                    <HStack spacing={4}>
                      <Link
                        href="https://www.youtube.com/watch?v=34AVebZPCwI&t=1554s"
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaYoutube
                          color="red"
                          size={35}
                          _hover={{ transform: 'scale(2)' }}
                        />
                      </Link>
                      <Link
                        href=""
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaSpotify color="lime" size={30} />
                      </Link>
                    </HStack>
                  </VStack>
                </Box>
              </Flex>
            </Flex>
          </Center>
        </Box>
      </Container>
    </>
  );
};

export default MoreGoreFull;

import {
  SimpleGrid,
  Box,
  Container,
  Stack,
  Heading,
  Text,
  Button,
  Link,
  Center,
} from '@chakra-ui/react';
import React from 'react';
import MoreGoreCard from './albumpreviewcards/MoreGoreCard';
import PlausibilityCard from './albumpreviewcards/PlausibilityCard';
import SlaughterhausCard from './albumpreviewcards/SlaughterhausCard';
import LtDanCard from './albumpreviewcards/LtDanPreviewCard';
import BiologicalExtinctionCard from './albumpreviewcards/BiologicalExtinctionCard';
import GoreInSpaceCard from './albumpreviewcards/GoreInSpaceCard';

const AlbumMap = ({ albumScrollRef }) => {
  return (
    <>
      <Container maxW={'5xl'}>
        <Center>
          <Heading
            fontStyle={'italic'}
            fontSize="5xl"
            color="white"
            mb={6}
            ref={albumScrollRef}
          >
            MUSIC
          </Heading>
        </Center>
        <SimpleGrid columns={[1, null, 3]} spacing={4}>
          {/* <Link href="/straight-from-the-slaughterhaus">
            <SlaughterhausCard />
          </Link> */}
          <Link href="/gore-in-space">
            <GoreInSpaceCard />
          </Link>
          <Link href="/plausibility-of-putridity">
            <PlausibilityCard />
          </Link>
          <Link href="/goredozer-biological-extinction">
            <BiologicalExtinctionCard />
          </Link>
          {/* <Link href="/gore-gore-and-more-gore">
            <MoreGoreCard />
          </Link> */}
          {/* <Link href="/lt-dan-split">
            <LtDanCard />
          </Link> */}
        </SimpleGrid>
      </Container>
    </>
  );
};

export default AlbumMap;

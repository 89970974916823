import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Container,
  Center,
  Flex,
  Image,
  Heading,
  ChakraProvider,
  chakra,
  Link,
  VStack,
  Button,
  HStack,
  Icon,
} from '@chakra-ui/react';
import ReactAudioPlayer from 'react-audio-player';
import audioSong from '../../audio/test.mp3';
import SubHeader from '../SubHeader';
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaSpotify,
  FaPlayCircle,
  FaRegPlayCircle,
} from 'react-icons/fa';

//audio player testing
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

//audio imports
import hygienicallyAltered from '../../audio/plausibility/01HygienicallyAltered.mp3';
import morbidActs from '../../audio/plausibility/02MorbidActsOfMedicalMisconduct.mp3';
import tamperingWithCadavers from '../../audio/plausibility/03TamperingWithCadavers.mp3';
import jarFullOfGenitals from '../../audio/plausibility/04JarFullOfGenitals.mp3';
import midnightPuker from '../../audio/plausibility/05MidnightPuker.mp3';
import surgicalNightmare from '../../audio/plausibility/06SurgicalNightmare.mp3';
import forensicNegligence from '../../audio/plausibility/07ForensicNegligenceDueToMedicalMalpractice.mp3';
import absurdHuman from '../../audio/plausibility/08AbsurdHuman.mp3';
import minceTheMeatMonger from '../../audio/plausibility/09MinceTheMeatMonger.mp3';
import idioticSympathizer from '../../audio/plausibility/10IdioticSympathizer.mp3';
import unexpectedlyDeceased from '../../audio/plausibility/11UnexpectedlyDeceased.mp3';
import legalizedMurder from '../../audio/plausibility/12LegalizedMurder.mp3';
import haggusCares from '../../audio/plausibility/13HaggusCares.mp3';
import corpsesInTheCloset from '../../audio/plausibility/14CorpsesInTheCloset.mp3';
import humanDisgrace from '../../audio/plausibility/15HumanDisgrace.mp3';

const SlaughterhausFull = () => {
  //album data
  const songs = [
    {
      title: '1. Hygienically Altered',
      audioFile: hygienicallyAltered,
    },
    {
      title: '2. Morbid Acts Of Medical Misconduct',
      audioFile: morbidActs,
    },
    {
      title: '3. Tampering With Cadavers',
      audioFile: tamperingWithCadavers,
    },
    {
      title: '4. Jar Full Of Genitals',
      audioFile: jarFullOfGenitals,
    },
    {
      title: '5. Midnight Puker',
      audioFile: midnightPuker,
    },
    {
      title: '6. Surgical Nightmare',
      audioFile: surgicalNightmare,
    },
    {
      title: '7. Forensic Negligence Due To Unrecognizable Stench',
      audioFile: forensicNegligence,
    },
    {
      title: '8. Absurd Human',
      audioFile: absurdHuman,
    },
    {
      title: '9. Mince The Meat Monger',
      audioFile: minceTheMeatMonger,
    },
    {
      title: '10. Idiotic Sympathizer',
      audioFile: idioticSympathizer,
    },
    {
      title: '11. Unexpectedly Deceased',
      audioFile: unexpectedlyDeceased,
    },
    {
      title: '12. Legalized Murder',
      audioFile: legalizedMurder,
    },
    {
      title: '13. Haggus Cares',
      audioFile: haggusCares,
    },
    {
      title: '14. Corpses In The Closet',
      audioFile: corpsesInTheCloset,
    },
    {
      title: '15. Human Disgrace',
      audioFile: humanDisgrace,
    },
  ];

  //state variables
  const [currentlyPlayingTitle, setCurrentlyPlayingTitle] = useState(
    '1. Hygienically Altered'
  );
  const [srcAudio, setSrcAudio] = useState(hygienicallyAltered);

  //audio track click handler
  const audioClick = title => {
    setSrcAudio();
    setCurrentlyPlayingTitle(title);
  };

  //useEffect for src audio
  useEffect(() => {
    songs.map(song => {
      if (song.title === currentlyPlayingTitle) {
        setSrcAudio(song.audioFile);
      }
    });
  }, [currentlyPlayingTitle]);

  return (
    <>
      <SubHeader />

      <Container
        mb={{ base: 10, md: 0, lg: 36 }}
        mt={{ base: 0, md: 0, lg: 0 }}
        maxW={'6xl'}
      >
        <Box>
          <Center>
            <Flex
              direction={{ base: 'column', md: '', lg: 'row' }}
              mt={{ base: 8, md: 0, lg: 20 }}
            >
              <Flex
                direction={'column'}
                // mt={4}
              >
                <Box
                  display={{ base: 'block', md: '', lg: 'none' }}
                  mb={10}
                  mt={10}
                >
                  <Image
                    // maxW={'2xl'}
                    // maxW={{ base: 'sm', md: 'xl', lg: '2xl' }}
                    // px={{ base: 4, md: 3, lg: 5 }}
                    src="https://ik.imagekit.io/v66nb6oaq/Haggus/plausibility_QUMLNERMJ.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1667939500307"
                  />
                </Box>
                <Heading color="gray.300" mb={6}>
                  Haggus - Plausibility Of Putridity (2018)
                </Heading>
                <Box mb={0}>
                  <Box mb={0} p={4}>
                    <Heading
                      color="white"
                      pb={4}
                      fontSize="3xl"
                      color="gray.300"
                    >
                      {currentlyPlayingTitle}
                    </Heading>
                    <ReactAudioPlayer src={srcAudio} controls autoPlay />
                  </Box>

                  {songs.map(song => (
                    <div>
                      <HStack m={2}>
                        <Icon
                          h="25px"
                          w="25px"
                          color="gray.300"
                          _hover={{
                            transform: 'scale(1.2)',
                            color: 'green.300',
                          }}
                          as={FaPlayCircle}
                          onClick={() => audioClick(song.title)}
                        />

                        <Text
                          mb={3}
                          mt={3}
                          color="gray.300"
                          fontSize={{ base: 'md', md: '', lg: 'lg' }}
                        >
                          {song.title}
                        </Text>
                      </HStack>
                    </div>
                  ))}
                </Box>
              </Flex>
              <Flex direction={'column'}>
                <Box display={{ base: 'none', md: '', lg: 'block' }}>
                  <Image src="https://ik.imagekit.io/v66nb6oaq/Haggus/plausibility_QUMLNERMJ.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1667939500307" />
                </Box>
                <Box color="gray.400" mt={4} fontStyle={'italic'}>
                  <chakra.h2 fontSize={'lg'} fontWeight="900" mb={4}>
                    Haggus - Plausibilty Of Putridity (2018)
                  </chakra.h2>

                  <Box mb={4}>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Track Listing :
                    </chakra.h2>
                    <Text>1. Hygienically Altered</Text>
                    <Text>2. Morbid Acts Of Medical Misconduct</Text>
                    <Text>3. Tampering With Cadavers</Text>
                    <Text>4. Jar Full Of Genitals</Text>
                    <Text>5. Midnight Puker</Text>
                    <Text>6. Surgical Nightmare</Text>
                    <Text>
                      7. Forensic Negligence Due To Unrecognizable Stench
                    </Text>
                    <Text>8. Absurd Human </Text>
                    <Text>9. Mince The Meat Monger</Text>
                    <Text>10. Idiotic Sympathizer</Text>
                    <Text>11. Unexpectedly Deceased</Text>
                    <Text>12. Legalized Murder</Text>
                    <Text>13. Haggus Cares</Text>
                    <Text>14. Corpses In The Closet</Text>
                    <Text>15. Human Disgrace</Text>
                  </Box>

                  <Box>
                    <chakra.h2 fontSize={'lg'} fontWeight="700">
                      Credits :
                    </chakra.h2>
                    <chakra.h2 fontSize={'lg'}>
                      Haggus on this recording is
                    </chakra.h2>
                    <Text>Hambone : Guitar, Bass, Vocals</Text>
                    <Text>Lil Giz : Drums</Text>
                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Recorded, mixed and mastered by Hambone <br /> in Oakland
                      and Dunsmuir, California throughout 2018.
                    </chakra.h2>

                    <chakra.h2 fontSize={'lg'} mt={4}>
                      Artwork / Layout by Hambone.
                    </chakra.h2>
                  </Box>

                  <VStack
                    align={'left'}
                    w="150px"
                    mt={7}
                    spacing={4}
                    // mx={{ base: '30%', md: '', lg: 'none' }}
                  >
                    <Link
                      href="https://drive.google.com/drive/folders/13tmI9aRTj7aPXKAhmCiiS_8prxfRCQ8f?usp=sharing"
                      target="_blank"
                    >
                      <Button
                        color="white"
                        bg="blue.500"
                        _hover={{ bg: 'blue.400', transform: 'scale(1.05)' }}
                      >
                        Download
                      </Button>
                    </Link>

                    {/* <Button
                      color="white"
                      bg="green.500"
                      _hover={{ bg: 'green.400', transform: 'scale(1.05)' }}
                    >
                      Donate
                    </Button>
                    <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Vinyl
                    </Button>
                    <Button
                      color="black"
                      bg="gray.300"
                      _hover={{ bg: 'gray.100', transform: 'scale(1.05)' }}
                    >
                      Buy Cassette
                    </Button> */}

                    <HStack spacing={4}>
                      <Link
                        href="https://www.youtube.com/watch?v=DZa9fLjEweo"
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaYoutube
                          color="red"
                          size={35}
                          _hover={{ transform: 'scale(2)' }}
                        />
                      </Link>
                      {/* <Link
                        href=""
                        target="_blank"
                        _hover={{ transform: 'scale(1.2)' }}
                      >
                        <FaSpotify color="lime" size={30} />
                      </Link> */}
                    </HStack>
                  </VStack>
                </Box>
              </Flex>
            </Flex>
          </Center>
        </Box>
      </Container>
    </>
  );
};

export default SlaughterhausFull;

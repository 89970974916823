import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ChakraProvider, theme, Box } from '@chakra-ui/react';

import Home from './components/Home';
import Footer from './components/Footer';
import Header from './components/Header';
import SlaughterhausFull from './components/albumsfullview/SlaughterhausFull';
import PlausibilityFull from './components/albumsfullview/PlausibilityFull';
import MoreGoreFull from './components/albumsfullview/MoreGoreFull';
import LtDanSplitFull from './components/albumsfullview/LtDanSplitFull';
import BiologicalExtinctionFull from './components/albumsfullview/BiologicalExtinctionFull';
import GoreInSpaceFull from './components/albumsfullview/GoreInSpaceFull';

function App() {
  //useRef section
  const scrollToAllAlbumsRef = useRef();
  const scrollToMerchRef = useRef();

  //scroll handlers
  const scrollToAllAlbumsHandle = () => {
    scrollToAllAlbumsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToMerchhandle = () => {
    scrollToMerchRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  //album state
  const [currentAlbum, setCurrentAlbum] = useState(null);

  return (
    <ChakraProvider theme={theme}>
      <div style={{ backgroundColor: 'black' }}>
        {/* <Header albumScrollHandle={scrollToAllAlbumsHandle} /> */}
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  albumScrollHandle={scrollToAllAlbumsHandle}
                  albumScrollRef={scrollToAllAlbumsRef}
                  merchScrollHandle={scrollToMerchhandle}
                  merchScrollRef={scrollToMerchRef}
                />
              }
            />
            <Route
              path="/straight-from-the-slaughterhaus"
              element={<SlaughterhausFull />}
            />
            <Route
              path="/plausibility-of-putridity"
              element={<PlausibilityFull />}
            />
            <Route path="/gore-gore-and-more-gore" element={<MoreGoreFull />} />
            <Route path="/lt-dan-split" element={<LtDanSplitFull />} />
            <Route
              path="/goredozer-biological-extinction"
              element={<BiologicalExtinctionFull />}
            />
            <Route path="/gore-in-space" element={<GoreInSpaceFull />} />
          </Routes>
        </Router>
        <Footer />
      </div>
    </ChakraProvider>
  );
}

export default App;

import React from 'react';
import { Box, Flex, Stack, Image, Link } from '@chakra-ui/react';

const SubHeader = () => {
  return (
    <>
      <Box
        w="full"
        h="full"
        // backgroundImage="url(https://ik.imagekit.io/v66nb6oaq/Mincecore_com/subheader_mU46eShi6O.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677394635892)"
        bgPos="center"
        bgSize="cover"
      >
        <Flex
          align="center"
          // pos="relative"
          justify="center"
          boxSize="full"
          bg="blackAlpha.700"
        >
          {/* <Stack textAlign="center" alignItems="center" spacing={6}>
            <Link href="/">
              <Image
                maxW={{ base: '300px', md: 'xs', lg: '450px' }}
                px={{ base: 4, md: 0, lg: 0 }}
                src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/mincecore_ZHpwLZ77WY.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677383758870"
              />
            </Link>
          </Stack> */}
          <Image
            maxW={'400px'}
            src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/mincecore_ZHpwLZ77WY.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677383758870"
          />
        </Flex>
      </Box>
    </>
  );
};

export default SubHeader;

import React from 'react';
import { Flex, Box, Image, Link, chakra, Text } from '@chakra-ui/react';

const GoreInSpaceCard = () => {
  return (
    <>
      <Flex
        _dark={{
          bg: 'black',
        }}
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          w="xs"
          bg="white"
          _dark={{
            bg: 'gray.800',
          }}
          shadow="lg"
          overflow="hidden"
          _hover={{
            transform: 'scale(1.03)',
          }}
        >
          <Image
            w="full"
            h="full"
            fit="cover"
            src="https://ik.imagekit.io/v66nb6oaq/Mincecore_com/maxresdefault_uN0WYYG-UX.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1678157710921"
            alt="album"
          />
        </Box>
      </Flex>
    </>
  );
};

export default GoreInSpaceCard;

import React from 'react';
import { Flex, Box, Image, Link, chakra, Text } from '@chakra-ui/react';

const PlausibilityCard = () => {
  //album data
  const albums = [
    {
      title: 'Straight From The Slaughterhaus',
      image:
        'https://ik.imagekit.io/v66nb6oaq/Haggus/slaughterhaus_u1bKIw3As.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1667938481786',
      year: '2020',
    },
    {
      title: 'Plausibility Of Putridity',
      image:
        'https://ik.imagekit.io/v66nb6oaq/Haggus/plausibility_QUMLNERMJ.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1667939500307',
      year: '2018',
    },
    {
      title: 'Gore, Gore And More Gore',
      image:
        'https://ik.imagekit.io/v66nb6oaq/Haggus/moregore_BOz1tdwze.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667940200317',
      year: '2018',
    },
  ];

  return (
    <>
      <Flex
        _dark={{
          bg: 'black',
        }}
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          w="xs"
          bg="white"
          _dark={{
            bg: 'gray.800',
          }}
          shadow="lg"
          overflow="hidden"
          _hover={{
            transform: 'scale(1.03)',
          }}
        >
          <Image
            w="full"
            h="full"
            fit="cover"
            src="https://ik.imagekit.io/v66nb6oaq/Haggus/plausibility_QUMLNERMJ.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1667939500307"
            alt="album"
          />
        </Box>
      </Flex>
    </>
  );
};

export default PlausibilityCard;
